import { useState } from 'react'
import { IconButton } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import styles from './Attribution.module.scss'
import { CopiedTooltip } from './TopicCluster'
import CmxTextField from 'Components/CmxTextField/CmxTextField'

export default function Attribution() {
  const [showAttributionTooltip, setShowAttributionTooltip] = useState<boolean>(false)
  const [showAdaptationTooltip, setShowAdaptationTooltip] = useState<boolean>(false)

  const attributionText =
    '<p>© <span><script type="text/javascript">document.write(new Date().getFullYear())</script></span> Ignite Healthwise, LLC. This information does not replace the advice of a doctor. Ignite Healthwise, LLC disclaims any warranty or liability for your use of this information.</p>'
  const adaptationText =
    '<p>© Copyrighted material adapted with permission from Ignite Healthwise, LLC. This information does not replace the advice of a doctor.</p>'

  return (
    <div className={styles.wrapper}>
      <div className={styles.attributionSection}>
        <div className={styles.attributionTitle}>Attribution requirements</div>
        <p className={styles.attributionSummary}>
          Healthwise’s copyright notice and disclaimer must be included with, near, or next to where
          you have used or adapted Healthwise content. Or you can link to the notice and disclaimer
          on the same page as the Healthwise content or adaptations. For example, you could make the
          word “Source” or “Contributions” a hyperlink that goes to a page that has the notice and
          disclaimer.
        </p>
      </div>
      <div className={styles.attributionSection}>
        <div className={styles.attributionTitle}>Healthwise Content</div>
        <p className={styles.attributionSummary}>
          {`Include this statement each place that you’ve used Healthwise content (the year needs to reflect the current year):`}
        </p>
        <p className={styles.attributionQuote}>
          "
          <span className={styles.disclaimerText}>
            © {new Date().getFullYear()} Ignite Healthwise, LLC. This information does not replace
            the advice of a doctor. Ignite Healthwise, LLC disclaims any warranty or liability for
            your use of this information.
          </span>
          "
        </p>
        <div className={styles.contentCopy}>
          <CmxTextField
            id='copyright-textfield'
            fullWidth
            value={attributionText}
            inputProps={{ spellCheck: 'false' }}
            mode='light'
          />
          <CopiedTooltip open={showAttributionTooltip} setOpen={setShowAttributionTooltip}>
            <IconButton
              className={styles.copy}
              onClick={() => {
                setShowAttributionTooltip(true)
                navigator.clipboard.writeText(attributionText)
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </CopiedTooltip>
        </div>
      </div>
      <div className={styles.attributionSection}>
        <div className={styles.attributionTitle}>Adaptations</div>
        <p className={styles.attributionSummary}>
          Include this statement each place that you’ve used adapted Healthwise content:
        </p>
        <p className={styles.attributionQuote}>
          "
          <span className={styles.disclaimerText}>
            © Copyrighted material adapted with permission from Ignite Healthwise, LLC. This
            information does not replace the advice of a doctor.
          </span>
          "
        </p>
        <div className={styles.contentCopy}>
          <CmxTextField
            id='adaptations-textfield'
            fullWidth
            value={adaptationText}
            inputProps={{ spellCheck: 'false' }}
            mode='light'
          />
          <CopiedTooltip open={showAdaptationTooltip} setOpen={setShowAdaptationTooltip}>
            <IconButton
              className={styles.copy}
              onClick={() => {
                setShowAdaptationTooltip(true)
                navigator.clipboard.writeText(adaptationText)
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </CopiedTooltip>
        </div>
      </div>
    </div>
  )
}
